import React from 'react'
import { Helmet } from 'react-helmet'

const HomeHelmet = () => {
  return (
    <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
    <link rel="icon" href="/favicon.ico" type="image/x-icon" />
    <title>Craftsman - Home - International Retail Specialists</title>
    <meta
      name="description"
      content="Craftsman offers a comprehensive turnkey solution for clients, including services such as 3D design, manufacturing, installations/removals, afterworks & storage."
    />
    <meta
      property="og:title"
      content="Craftsman - Services - International Retail Specialists"
    />
    
    <meta
      property="og:description"
      content="Craftsman - International Retail Specialists - For four decades, Craftsman has been at the forefront of crafting bespoke luxury retail furniture for retail markets, globally."
    />

    <meta
      property="og:image"
      content="https://craftsman-llc.com/images/Craftsman.png"
    />

    <meta property="og:url" content="https://craftsman-llc.com/" />
    
    <meta property="og:type" content="website" />
    <link
      rel="apple-touch-icon"
      sizes="72x72"
      href="/apple-touch-icon.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicon-32x32.png"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicon-16x16.png"
    />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
    <meta name="msapplication-TileColor" content="#da532c" />
    <meta name="theme-color" content="#ffffff" />
  </Helmet>
  )
}

export default HomeHelmet