import React, { useState } from "react";

import HeroImageCarousel from "./HeroImageCarousel";

const HomepageHero = () => {

  return (
    <div className="container-xl mx-auto homepage-hero flex-col">
      <div className="centered pb-5 gap-4 flex-col">
        <div className="content flex-col">
          <h3>International Retail Specialists</h3>
          <p>
            Over four decades, Craftsman has been at the forefront of
            manufacturing bespoke luxury furniture for retail markets globally.
            Our unwavering dedication to quality and customer satisfaction has
            fuelled our growth and allowed us to cultivate a substantial and
            diverse portfolio.
          </p>
        </div>
        <HeroImageCarousel />
      </div>
    </div>
  );
};

export default HomepageHero;
