import React from "react";

const Mail = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        id="Selection"
        fill="#667190fe"
        stroke="black"
        strokeWidth="1"
        d="M 39.00,64.09
           C 39.00,64.09 82.00,64.09 82.00,64.09
             82.00,64.09 162.00,64.09 162.00,64.09
             162.00,64.09 407.00,64.09 407.00,64.09
             407.00,64.09 467.00,64.09 467.00,64.09
             490.66,64.28 511.88,83.99 512.00,108.00
             512.00,108.00 512.00,115.00 512.00,115.00
             511.74,136.41 497.01,146.66 481.00,157.33
             481.00,157.33 461.00,170.67 461.00,170.67
             461.00,170.67 323.00,262.67 323.00,262.67
             323.00,262.67 294.00,282.00 294.00,282.00
             287.88,286.08 279.82,291.87 273.00,294.30
             266.09,296.77 261.21,297.08 254.00,297.00
             239.09,296.82 230.86,290.57 219.00,282.67
             219.00,282.67 189.00,262.67 189.00,262.67
             189.00,262.67 48.00,168.67 48.00,168.67
             26.72,154.48 0.05,142.36 0.00,113.00
             0.00,113.00 0.00,107.00 0.00,107.00
             0.26,85.09 18.37,68.30 39.00,64.09 Z
           M 0.00,171.00
           C 0.00,171.00 58.00,209.00 58.00,209.00
             58.00,209.00 171.00,284.33 171.00,284.33
             171.00,284.33 204.00,306.33 204.00,306.33
             219.61,316.74 233.41,325.77 253.00,326.00
             279.85,326.31 290.62,317.92 312.00,303.67
             312.00,303.67 341.00,284.33 341.00,284.33
             341.00,284.33 454.00,209.00 454.00,209.00
             454.00,209.00 512.00,171.00 512.00,171.00
             512.00,171.00 512.00,403.00 512.00,403.00
             511.96,429.09 490.50,447.96 465.00,448.00
             465.00,448.00 107.00,448.00 107.00,448.00
             107.00,448.00 46.00,448.00 46.00,448.00
             20.99,447.88 0.04,428.31 0.00,403.00
             0.00,403.00 0.00,171.00 0.00,171.00 Z"
      />
    </svg>
  );
};

export default Mail;
