import React from "react";
import { useActionData, useParams } from "react-router-dom";
import caseStudies from "../../../data/popUpData.json";
import Layout from "../../Layout";
import Footer from "../../../components/global/Footer";
import { useEffect } from "react";
import Close from "../../../components/icons/Close";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Button from "../../../components/global/Button";

const CaseStudy = () => {
  const params = useParams();
  const studyName = params.name;
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");
  const [executionArray, setExecutionArray] = useState([]);

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  const removeModel = (bool) => {
    setModel(bool);
    setTempImgSrc("");
  };

  const foundStudy = caseStudies.slides.find(
    (slide) => slide.name === studyName
  );

  useEffect(() => {
    if (foundStudy && Object.values(foundStudy).length > 0) {
      setExecutionArray(
        Object.keys(foundStudy.execution).map((key) => ({
          id: key,
          description: foundStudy.execution[key],
        }))
      );
    }
  }, [foundStudy]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <title>
          {`Craftsman - ${studyName} - International Retail Specialists`}
        </title>
        <meta
          name="description"
          content={`Discover our compact yet effective HPP and Dress Up solutions for ${studyName}, showcasing brands with style across the Middle-East and West Asia. Explore our portfolio!`}
        />
        <meta
          property="og:title"
          content="Craftsman - ${studyName} - International Retail Specialists"
        />
        <meta
          property="og:description"
          content={`Discover our compact yet effective HPP and Dress Up solutions for ${studyName}, showcasing brands with style across the Middle-East and West Asia. Explore our portfolio!`}
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />
        <meta
          property="og:url"
          content={`https://craftsman-llc.com/case-study/${studyName}`}
        />{" "}
        {/* Include the dynamic studyName */}
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>

      <div className="case-study-container">
        <Layout />

        <div className="container-xl relative mx-auto mb-10">
          <div className="absolute right-0">
            <Button
              link={"../portfolio/pop-up"}
              text={"Go Back"}
              backgroundColor={"#667190fe"}
              textColour={"#fefefe"}
            />
          </div>
          <div
            onClick={() => removeModel(false)}
            className={model ? "model open" : "model"}
          >
            <img  src={tempImgSrc} />
            <div onClick={() => removeModel(false)}>
              <Close fill={"#fff"} />
            </div>
          </div>

          <h3 className="fs-8 case-study-title">{studyName}</h3>
          {/* <p className="fs-0 case-study-desc">{foundStudy.desc}</p> */}
          <div className="case-study grid mt-5">
            <div className="gap-3 left">
              <div className="details grid">
                <div className="flex-col mb-2">
                  <h4 className="white fs-3">Date : </h4>
                  <p className="white fs-0">{foundStudy.date}</p>
                </div>
                <div className="flex-col">
                  <h4 className="white fs-3">Timeline : </h4>
                  <p className="white fs-0">{foundStudy.scope}</p>
                </div>
                <div className="flex-col mb-2">
                  <h4 className="white fs-3">Scope : </h4>
                  <p className="white fs-0">{foundStudy.timeline}</p>
                </div>
                <div className="flex-col ">
                  <h4 className="white fs-3">Description : </h4>
                  <p className="white fs-0">{foundStudy.desc}</p>
                </div>
              </div>
              <div
                onClick={() => getImg(foundStudy.src1)}
                className="image-container"
              >
                <img  src={foundStudy.src1} />
              </div>
              <div
                className="image-container"
                style={{ maxHeight: "450px" }}
                onClick={() => getImg(foundStudy.src2)}
              >
                <img  src={foundStudy.src2} />
              </div>
              <div
                onClick={() => getImg(foundStudy.src4)}
                className="image-container"
              >
                <img  src={foundStudy.src4} />
              </div>
            </div>
            <div className="gap-5 right">
              <div className="image-container-right">
                <img
                  onClick={() => getImg(foundStudy.src3)}
                  src={foundStudy.src3}
                />
              </div>
              <div style={{ maxHeight: "310px" }} className="image-container">
                <img
                  onClick={() => getImg(foundStudy.src5)}
                  src={foundStudy.src5}
                />
              </div>
              <div className="highlights">
                <h4>Execution Highlights</h4>
                <ul className="pl-3 flex-col gap-2">
                  {executionArray.map((item) => (
                    <li className="fs-0" key={item.id}>
                      {item.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CaseStudy;
