import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import popUpImages from "../../data/popUpData.json";
import podiumImages from "../../data/recentProjectsData.json";
import dressUpsImages from "../../data/dressUpsData.json";
import Close from "../../components/icons/Close";
import Layout from "../Layout";
import Footer from "../../components/global/Footer";
import { Helmet } from "react-helmet";

const PopUp = () => {
  const history = useNavigate();
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState("");
  const [wideImage, setWideImage] = useState(false);
  let gallery = [];
  const params = useParams();
  let title = params.title.replace(/-/g, " ");
  title = title.toLowerCase();


  if (title == "pop up") {
    gallery = popUpImages;
  } else if (title === "podiums") {
    gallery = podiumImages;
  } else if (title === "hpp & dress ups") {
    gallery = dressUpsImages;
  }

  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };

  const removeModel = (bool) => {
    setModel(bool);
    setTempImgSrc("");
  };

  const goToPage = (name, src) => {
    if (name !== undefined) {
      history(`../portfolio/case-study/${name}`);
    } else {
      getImg(src);
    }
  };

  useEffect(() => {
    setWideImage(title == "pop up" || title == "circle" ? true : false);
  }, [title]);

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>

        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <title>{`Craftsman - Pop up - International Retail Specialists`}</title>
        <meta
          name="description"
          content={`Discover our compact yet effective Pop Up solutions, showcasing brands with style across the Middle-East and West Asia. Explore our portfolio!`}
        />
        
        <meta
          property="og:title"
          content={`Craftsman - Pop up - International Retail Specialists`}
        />
        <meta
          property="og:description"
          content={`Discover our compact yet effective Pop Up solutions, showcasing brands with style across the Middle-East and West Asia. Explore our portfolio!`}
        />
        <meta
          property="og:image"
          content="https://craftsman-llc.com/images/Craftsman.png"
        />

        
        <meta property="og:url" content={`https://craftsman-llc.com/pop-up`} />
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className="gallery-container">
        <Layout />
        <div className="popup-page container-xl mx-auto flex-col">
          <div
            onClick={() => removeModel(false)}
            className={model ? "model open" : "model"}
          >
            <img  src={tempImgSrc} />
            <div onClick={() => removeModel(false)}>
              <Close fill={"#fff"} />
            </div>
          </div>

          <h2 className="capitalize fs-7">{title}</h2>
          <div className={wideImage ? "gallery-wide" : "gallery"}>
            {gallery.slides.map((image) => (
              <div
                className={`${
                  wideImage ? "wide-image-container" : "image-container"
                }`}
                onClick={() =>
                  wideImage
                    ? goToPage(image.name, image.src)
                    : getImg(image.src)
                }
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  onClick={() =>
                    wideImage
                      ? goToPage(image.name, image.src)
                      : getImg(image.src)
                  }
                  className="thumbnail"
                />
                <div className="wide-image-overlay">
                  <h3 className="fs-6">{image.name}</h3>
                </div>
                <span className="line"></span>
                <span className="line line-bottom"></span>
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default PopUp;
