import React from "react";

const Phone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        id="Selection"
        fill="#667190fe"
        stroke="black"
        strokeWidth="5"
        d="M 119.00,16.47
           C 135.90,14.11 145.64,23.09 154.86,36.00
             171.58,59.43 186.30,83.96 200.42,109.00
             206.03,118.94 216.60,140.73 215.91,152.00
             214.92,168.12 201.23,177.66 190.00,187.27
             185.03,191.53 175.38,197.67 176.35,205.00
             177.21,211.56 187.51,231.62 191.15,238.00
             209.76,270.55 235.27,296.78 267.00,316.68
             276.26,322.49 285.96,327.55 296.00,331.85
             298.90,333.09 306.05,336.23 309.00,336.05
             314.17,335.74 320.72,326.80 324.00,323.00
             332.79,312.82 341.28,300.54 355.00,296.92
             366.72,293.84 383.57,301.55 394.00,306.75
             416.72,318.09 437.90,331.60 459.00,345.67
             467.40,351.27 482.51,360.49 488.48,368.00
             503.82,387.28 493.61,402.04 480.25,418.00
             463.81,437.61 429.64,465.50 408.00,480.00
             401.21,484.54 387.66,493.71 380.00,495.47
             377.12,496.13 373.01,496.03 370.00,496.00
             361.28,495.89 347.68,492.54 339.00,490.37
             310.13,483.15 282.99,470.57 257.00,456.30
             225.19,438.84 195.19,416.09 168.00,392.15
             115.09,345.57 68.83,287.30 39.31,223.00
             32.04,207.18 25.35,187.89 21.13,171.00
             19.09,162.87 16.10,150.19 16.00,142.00
             15.83,126.78 21.80,119.31 30.00,107.00
             41.71,89.43 54.73,72.64 68.93,57.00
             80.69,44.04 101.71,20.58 119.00,16.47 Z"
      />
    </svg>
  );
};

export default Phone;
