import React from "react";
import HomepageServices from "../components/homepage/HomepageServices-Copy";
import HomepageAbout from "../components/homepage/HomepageAbout";
import HomepagePortfolio from "../components/homepage/HomepagePortfolio";
import BrandCarousel from "../components/global/BrandCarousel";
import ContactForm from "../components/global/ContactForm";
import HomepageHero from "../components/homepage/HomepageHero";
import Footer from "../components/global/Footer";
import Layout from "./Layout";
import HomeHelmet from "../components/helmets/HomeHelmet";

const Home = () => {
  return (
    <>
      <HomeHelmet />
      <div className="top-content">
        <div className="relative">
          <div className="underlay"></div>
          <div className="underlay-color"></div>
            <Layout />
            <HomepageHero />
        </div>
      </div>
      {/* <div id="content-ontop" className="bg"></div> */}
      <div className="container-xl mx-auto mt-10 sm:mt-5 mb-15 homepage-portfolio-container flex-col">
        <HomepagePortfolio />
      </div>

      <div className="background-now">
        <div className="background-now-underlay"></div>
        <div className="background-now-color"></div>
        <div className="background-now-overlay">
          <div className="mt-10 homepage-services-container flex-col">
            <div className="container-xl py-10 mx-auto">
              <HomepageServices />
            </div>
          </div>
        </div>
      </div>

      <div className="homepage-about-section my-5 ">
        <div className="container-xl mx-auto py-5 lex-col">
          <HomepageAbout />
        </div>
      </div>

      <div className="brand-container mb-5">
        <BrandCarousel />
      </div>
      <div className="homepage-contact-container my-10 container-xl mx-auto">
        <ContactForm />
      </div>
      <Footer />
    </>
  );
};

export default Home;
