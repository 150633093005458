import React from "react";
import Logo from "../assets/Logo";
import Linkedin from "../icons/Linkedin";
import { Outlet, Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="relative mt-5">
      <footer className="flex-row">
        <div className="flex-col">
          <Link className="logo-container" to="/">
            <Logo color={"#fefefe"}/>
          </Link>
          <div className="footer-bottom flex-row">
            <div className="footer-nav flex-col">
              <Link to="/services">Services</Link>
              <div className="dropdown flex-col">
                <Link to="#">Portfolio</Link>
                <div className="dropdown-content flex-col">
                  <Link to="/portfolio/pop-up">Pop Up</Link>
                  <Link to="/retail-displays">Retail Displays</Link>
                  <Link to="/press-ups">Press Ups</Link>
                </div>
              </div>
              <Link to="/about">About</Link>
              <Link to="/contact">Contact</Link>
            </div>
            <div className="flex-row footer-socials">
              <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/craftsman-duty-free/">
                <Linkedin />
              </a>
            </div>
          </div>
          <div className="trademark">
            <p>Copyright © 2022 <strong>Craftsman Ltd</strong> All rights reserved.</p>
            <p>Powered By <a href="#">Digiwave</a></p>
          </div>
          {/* <div className="flex-row certificates">
              <img  src="/images/certificates/certificate (1).png" />
              <img  src="/images/certificates/certificate (2).png" />
          </div> */}
        </div>
      </footer>
      <Outlet />
    </div>
  );
};

export default Footer;
