import React, { useEffect } from "react";

import Swiper from "swiper";
import Slides from "../../data/brandLogoData.json";
import { Autoplay } from 'swiper/modules';

import 'swiper/swiper-bundle.css';

const BrandCarousel = () => {

  useEffect(() => {
    const brandSwiper = new Swiper(".brand-swiper", {
      spaceBetween: 55,
      slidesPerView: "auto",
      autoplay: { delay: 2000 ,disableOnInteraction: false },
      modules: [Autoplay]
    });
  }, []);

  return (
    <div className="brand-swiper">
      <div className="swiper-wrapper">
        {Slides.slides.map((item, idx) => {
          return (
            <div className="swiper-slide" data-swiper-autoplay="2000" key={idx}>
              <img  src={item.src} alt={item.alt} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BrandCarousel;
