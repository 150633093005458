import React, { useEffect, useState } from "react";
import HomepageServicesContent from "./HomepageServicesContent";
import Swiper from "swiper";

const HomepageServices = () => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);
  const [autoplay, setAutoplay] = useState(true);

  // useEffect(() => {
  //   if (autoplay) {
  //     const timeoutId = setTimeout(() => {
  //       activeSlideIndex < 2
  //         ? setActiveSlideIndex(activeSlideIndex + 1)
  //         : setActiveSlideIndex(0);
  //     }, 4000);

  //     return () => clearTimeout(timeoutId); // Clear timeout when component unmounts or when autoplay is toggled off
  //   }
  // }, [autoplay, activeSlideIndex, setActiveSlideIndex]);

  return (
    <div className="homepage-services flex-col">
      {activeSlideIndex === 0 && (
        <HomepageServicesContent
          header={"Design Development"}
          paragraph={
            "Our specialized technical design department offers comprehensive support with both 3D and CAD drawings, enabling seamless collaboration with our clients throughout every phase of the project. We adhere to industry standards, bringing our clients' ideas to life while navigating the boundaries of what is technologically feasible. "
          }
          class={"homepage-services-content flex-col active"}
        />
      )}
      {activeSlideIndex === 1 && (
        <HomepageServicesContent
          header={"Manufacturing"}
          paragraph={
            "Since our inception, we have dedicated ourselves to being specialists in crafting tailor-made retail fixtures and fit outs. Our in-house team excels in carpentry, metalwork, acrylic craftsmanship, and includes a dedicated printing department to meet all our clients' visual merchandising requirements. Our success is rooted in our extensive knowledge and expertise, positioning us as a preferred choice for some of the world's largest blue-chip companies seeking to showcasing their products optimally within the international travel retail sector. "
          }
          class={"homepage-services-content flex-col active"}
        />
      )}
      {activeSlideIndex === 2 && (
        <HomepageServicesContent
          header={"Installation"}
          paragraph={
            "The correct installation of a project is paramount to its overall success. Our skilled crew and experienced supervision staff are readily available to assist all our clients at any given time. Operating seamlessly across the emirates throughout the year and maintaining a reliable network within the surrounding GCC region and Southwest Asia. Our guarantee is that on all site installations there will be Craftsman team members to ensure on-time activation."
          }
          class={"homepage-services-content flex-col active"}
        />
      )}

      <div className="services-swiper">
        <div className={`swiper-wrapper`}>
          <div
            id="design"
            className={`swiper-slide ${activeSlideIndex === 0 ? "active" : ""}`}
            onClick={(e) => {
              setAutoplay(false);
              setActiveSlideIndex(0);
            }}
          >
            <img  src="/Icons/DesignIcon.webp"></img>
          </div>
          <div
            id="manufacture"
            className={`swiper-slide ${activeSlideIndex === 1 ? "active" : ""}`}
            onClick={(e) => {
              setActiveSlideIndex(1);
              setAutoplay(false);
            }}
          >
            <img  src="/Icons/ProjectManage.webp"></img>
          </div>
          <div
            id="installation"
            className={`swiper-slide ${activeSlideIndex === 2 ? "active" : ""}`}
            onClick={(e) => {
              setActiveSlideIndex(2);
              setAutoplay(false);
            }}
          >
            <img  src="/Icons/Installation.webp"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageServices;
